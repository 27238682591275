import { Renew } from '@carbon/icons-react'
import { Button } from 'australis'
import Image from 'next/image'
import styles from './Error.module.scss'

interface Props {
  title?: string
  subtitle?: string
  variant?: 'fullscreen'
  message?: string
}

export const Error: React.FC<Props> = ({
  title = 'Something went wrong',
  subtitle,
  message,
  variant,
}) => {
  const Body = () => {
    return (
      <div className={styles.Error}>
        <Image src='/error.svg' alt='Error' width={264} height={209} />
        <h5>{title}</h5>
        <p>
          {subtitle ?? (
            <>
              We are having some technical issues,
              <br />
              try to refresh the page.
            </>
          )}
        </p>
        <Button
          color='primary'
          onPress={() => window.location.reload()}
          icon={{ icon: <Renew />, position: 'start' }}
        >
          Refresh
        </Button>
        <div className={styles.ErrorMessage}>{message}</div>
      </div>
    )
  }

  if (variant === 'fullscreen') {
    return (
      <div className={styles.FullscreenWrapper}>
        <Body />
      </div>
    )
  }

  return <Body />
}
